import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
// import NaviBar1 from '../NaviBar1';


 
  Chart.register(zoomPlugin);
  
  let BarChart;
  
  
  function Charts7() {
      useEffect(() => {
          buildBarChart();
      }, []);
  
      const buildBarChart = () => {
          var ctx = document.getElementById("BarChart").getContext("2d");
  
          if (typeof BarChart !== "undefined") BarChart.destroy();
  
          BarChart = new Chart(ctx, {
              type: 'bar',
              data: {
                  labels: ['ГДК', 'Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
                  datasets: [
                   
                
                    {
                        type: 'bar',
                        label: 'Пил',
                        data: [1.0, 0.67, 0.67, 0.80, 0.60, 0.67, 0.87, 0.87, 0.87, 0.80, 0.80, 0.73, 0.67],
                        fill: true,
                        backgroundColor: 'rgb(138, 235, 12)',
                        tension: 0.1
                    },
                    {
                        type: 'bar',
                        label: 'Діоксид сірки',
                        data: [1.0, 0.14, 0.12, 0.12, 0.12, 0.16, 0.14, 0.14, 0.14, 0.08, 0.08, 0.08, 0.10],
                        fill: true,
                        backgroundColor: 'rgb(12, 235, 68)',
                        tension: 0.1
                     },
                    {
                        type: 'bar',
                        label: 'Оксид вуглецю',
                        data: [1.0, 0.49, 0.47, 0.60, 0.55, 0.58, 0.64, 0.60, 0.60, 0.48, 0.51, 0.45, 0.39],
                        fill: true,
                        backgroundColor: 'rgb(179, 235, 12)',
                        tension: 0.1
                    },
                    {
                        type: 'bar',
                        label: 'Діоксид азоту',
                        data: [1.0, 1.00, 0.98, 0.98, 1.08, 1.23, 1.25, 1.38, 1.20, 1.00, 1.00, 1.00, 1.10],
                        fill: true,
                        backgroundColor: 'rgb(235, 94, 12)',
                        tension: 0.1
                    },
                    {
                        type: 'bar',
                        label: 'Оксид азоту',
                        data: [1.0, 0.42, 0.42, 0.40, 0.45, 0.50, 0.52, 0.55, 0.50, 0.43, 0.43, 0.43, 0.47],
                        fill: true,
                        backgroundColor: 'rgb(179, 235, 12)',
                        tension: 0.1
                    },
                    {
                        type: 'bar',
                        label: 'Фтористий водень',
                        data: [1.0, 0.60, 0.60, 0.60, 0.60, 0.60, 0.80, 0.80, 0.80, 0.80, 0.80, 0.80, 0.80],
                        fill: true,
                        backgroundColor: 'rgb(194, 235, 12)',
                        tension: 0.1
                    },
                    {
                        type: 'bar',
                        label: 'Формальдегід',
                        data: [1.0, 2.67, 2.67, 2.67, 2.67, 2.67, 3.00, 3.00, 3.00, 3.00, 3.33, 3.00, 3.33],
                        fill: true,
                        backgroundColor: 'rgb(235, 12, 12)',
                        tension: 0.1
                    },
                  ]
              },
              });
          }
  
  
      return (
          <div className='graf'>
            {/* <NaviBar1 /> */}
              <h2>Середньомісячні концентрації забруднювальних речовин в атмосферному повітрі міста Львова</h2>
              <h3>(у долях ГДК) 2022 рік</h3>
              <canvas id="BarChart" width="1000" height="400"/>
          </div>
      )
  }
  

export default Charts7;