import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import Navigation from './Navigation';


Chart.register(zoomPlugin);

let BarChart;


function Charts13() {
    useEffect(() => {
        buildBarChart();
    }, []);

    const buildBarChart = () => {
        var ctx = document.getElementById("BarChart").getContext("2d");

        if (typeof BarChart !== "undefined") BarChart.destroy();

        BarChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['ГДК', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18'],
                datasets: [
            
                 {
                     type: 'bar',
                     label: 'рН',
                     data: [8.5, 8.0, 8.35, 7.9, 7.95, 7.45, 7.9, 6.97, 8.35, 7.9, 8.25, 8.1, 7.9, 8.25, 7.4, 7.9, 7.7, 7.1, 7.8],
                     fill: true,
                     backgroundColor: 'rgb(29, 240, 64)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'Залізо загальне',
                     data: [0.1, 1.12, 1.12, 0.81, 0.66, 0.75, 0.6, 1.0, 0.81, 0.61, 0.72, 0.84, 1.09, 0.79, 0.34, 0.49, 0.47, 0.96, 0.34],
                     fill: true,
                     backgroundColor: 'red',
                     tension: 0.1
                 },
                 {
                    type: 'bar',
                    label: 'Азот амонійний та аміак',
                    data: [0.5, 3.3, 6.5, 2.26, 9.1, 13.9, 2.42, 10.4, 1.83, 5.1, 1.06, 3.72, 14.7, 8.34, 0.92, 0.64, 4.3, 3.52, 0.66],
                    fill: true,
                    backgroundColor: 'red',
                    tension: 0.1
                },
                {
                    type: 'bar',
                    label: 'Нітрити',
                    data: [0.08, 0.135, 1.2, 0.09, 0.18, 0.235, 0.295, 0.05, 0.035, 0.34, 0.77, 0.04, 0.08, 0.035, 0.13, 0.125, 0.24, 0.115, 0.195],
                    fill: true,
                    backgroundColor: 'rgb(235, 112, 12)',
                    tension: 0.1
                },
                {
                    type: 'bar',
                    label: 'Фосфати',
                    data: [0.7, 3.224, 4.464, 1.984, 3.224, 11.5, 2.976, 2.976, 2.48, 2.976, 3.224, 4.96, 7.44, 3.224, 1.736, 2.976, 1.736, 1.488, 1.24],
                    fill: true,
                    backgroundColor: 'red',
                    tension: 0.1
                },
                {
                    type: 'bar',
                    label: 'СПАР',
                    data: [0.5, 1.32, 1.12, 0.66, 1.12, 0.5, 0.288, 1.4, 0.704, 0.168, 0.16, 0.125, 0.115, 0.05, 0.224, 0.096, 0.12, 0.224, 0.2],
                    fill: true,
                    backgroundColor: 'rgb(235, 212, 12)',
                    tension: 0.1
                },
                ]
            },
            });
        }


    return (
        <div className='graf'>
            <Navigation />
            <h2>Показники якості поверхневих водних об’єктів на території Львівської територіальної громади за 4 квартал 2022 року</h2>
            <h4>рН. Залізо загальне. Азот амонійний та аміак. Нітрити. Фосфати. СПАР</h4>
            <div className="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>потік поблизу Грибовицького полігону ТПВ</td>
        <td>2</td> 
        <td>річка «Полтва» (об’їзна дорога)</td> 
        <td>3</td>
        <td>річка «Млинівка» (с. Гряда)</td>
      </tr>
      <tr>
        <td>4</td>
        <td>річка «Миклашівка»</td>
        <td>5</td>
        <td>потік «Білогорський»</td>
        <td>6</td>
        <td>потік «Водяний»</td>
      </tr>
      <tr>
        <td>7</td>
        <td>потік «Лисиницький» (вул. Тракт-Глинянський, 150);</td>
        <td>8</td>
        <td>водойма на вул. Панаса Сотника;</td>
        <td>9</td>
        <td>річка «Зубра» на вул. Хоткевича,</td>
      </tr>
      <tr>
        <td>10</td>
        <td>потік у с. Підрясне</td>
        <td>11</td>
        <td>річка «Зимна Вода» (Вишенька)</td>
        <td>12</td>
        <td>потік смт Рудно (Сигнівка)</td>
      </tr>
      <tr>
        <td>13</td>
        <td>річка «Марунька»</td>
        <td>14</td>
        <td>водойма у парку «Піскові озера» (вул. Чупринки, 136)</td>
        <td>15</td>
        <td>водойма у парку «Архангела Михаїла»</td>
      </tr>
      <tr>
        <td>16</td>
        <td>річка «Зубра»</td>
        <td>17</td>
        <td>потік «Кривчицький» на вул. Старознесенській, 200</td>
        <td>18</td>
        <td>водойма у парку «Снопківський», вул. Бучми</td>
      </tr>
      
      
      
      
      
      
    </tbody>
  </table>
</div>
            <canvas id="BarChart" width="1000" height="400"/>
        </div>
    )
}



export default Charts13;