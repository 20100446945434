import Navigation from '../lvivregion/Navigation';


function PovWater  () {

    return (
    <>
    <Navigation />
    <h1 className="karta">Аналіз проб поверхневих вод</h1> 
    <h6 className="description">
    Оцінка якості поверхневих вод здійснювалася на основі аналізу
  інформації стосовно величин гідрохімічних показників у порівнянні з
  відповідними значеннями їх гранично допустимих концентрацій (ГДК) та
  фоновими показниками.<br></br>
  Гідрохімічні та гідрофізичні показники були поділені на такі групи
  відповідно до їх типу та/або кількісних характеристик:<br></br>
  1 група - компоненти сольового складу: (сума іонів, гідрокарбонати,
  хлориди, сульфати, іони магнію, кальцію, натрію);<br></br>
  2 група - показники трофо-сапробіологічного стану: завислі
  речовини, розчинений кисень, рН, розчинені органічні речовини (за
  показниками БСК5 та ХСК), сполуки головних біогенних елементів (азот
  амонійний, азот нітратний, азот нітритний, фосфати);<br></br>
  3 група – специфічні речовини: нафтопродукти, СПАР, феноли; важкі
  метали (залізо загальне, цинк, хром загальний, свинець, нікель, кадмій).
    </h6>
    
    </> 
    );
  }   
  
    export default PovWater;