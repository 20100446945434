import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import NaviBar from './NaviBar';


Chart.register(zoomPlugin);

let BarChart;


function Charts3() {
    useEffect(() => {
        buildBarChart();
    }, []);

    const buildBarChart = () => {
        var ctx = document.getElementById("BarChart").getContext("2d");

        if (typeof BarChart !== "undefined") BarChart.destroy();

        BarChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['Kysle Lake', 'Chyste Lake', 'Serednie Lake', 'Hlyboke Lake'],
                datasets: [
            
                {
                    type: 'bar',
                    label: 'HCO3-',
                    data: [36.6, 85.4, 109.8, 141.6],
                    fill: true,
                    backgroundColor: 'rgb(238, 251, 16)',
                    tension: 0.1
                },
                 {
                     type: 'bar',
                     label: 'Ca2+',
                     data: [120.2, 220.4, 430.9, 450.9],
                     fill: true,
                     backgroundColor: 'rgb(251, 157, 16)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'Mg2+',
                     data: [170.1, 63.2, 103.3, 30.4],
                     fill: true,
                     backgroundColor: 'rgb(251, 122, 16)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'Cl-',
                     data: [99.1, 49.1, 181.0, 89.7],
                     fill: true,
                     backgroundColor: 'rgb(251, 36, 16)',
                     tension: 0.1
                 },
                  {
                     type: 'bar',
                     label: 'Na',
                     data: [165, 160, 382.0, 162.0],
                     fill: true,
                     backgroundColor: 'rgb(16, 220, 251)',
                     tension: 0.1
                 },
                  {
                     type: 'bar',
                     label: 'K',
                     data: [41, 17.0, 26.6, 170],
                     fill: true,
                     backgroundColor: 'rgb(16, 32, 251)',
                     tension: 0.1
                 },
               
                ]
            },
            });
        }


    return (
        <div className='graf'>
            <NaviBar />
            <h2>Результати аналізу проб води у зоні впливу Роздільського ДГХП Сірка </h2>
            <h3>Аналіз проб води в листопаді 2021 року</h3>
            <canvas id="BarChart" width="1000" height="400"/>
        </div>
    )
}



export default Charts3;