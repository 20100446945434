// import NaviBar3 from '../NaviBar3';
// import SecondMenu from '../SecondMenu';


function  Vidxody () {

  return (
  <>
  {/* <NaviBar3 /> */}
  {/* <SecondMenu /> */}
  <h1 className="karta">Відходи</h1> 
  </> 
  );
}   

  export default Vidxody;