import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import NaviBar1 from './NaviBar1';
// import SecondMenu from './SecondMenu';


 
  Chart.register(zoomPlugin);
  
  let BarChart;
  
  
  function Charts6() {
      useEffect(() => {
          buildBarChart();
      }, []);
  
      const buildBarChart = () => {
          var ctx = document.getElementById("BarChart").getContext("2d");
  
          if (typeof BarChart !== "undefined") BarChart.destroy();
  
          BarChart = new Chart(ctx, {
              type: 'bar',
              data: {
                  labels: ['ГДК', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'],
                  datasets: [
                   
                    {
                        type: 'bar',
                        label: 'Mn',
                        data: [1500, 159.3672062, 426.9061938, 308.6479408, 211.3603, 308.1783147, 181.4605552, 244.7110467, 199.4806155, 268.9061751, 149.7202, 204.2135719, 252.888267, 397.0191962, 229.5956809, 259.1394442, 243.2692385, 262.4675195, 280.3860368, 206.3150849, 156.3322677, 234.5832168, 277.5210916, 181.6791492, 182.3351978, 278.6842894, 151.5113909, 179.6415151, 278.3689, 163.2819436, 182.415718, 92.21038961, 357.8778244, 264.2138, 269.251, 224.5213957, 143.6903335],
                        fill: true,
                        backgroundColor: 'rgb(251, 16, 231)',
                        tension: 0.1
                    },
                    {
                        type: 'bar',
                        label: 'Cu',
                        data: [100, 6.507593925, 11.56453546, 4.62215114, 9.5916, 5.283146965, 34.205712, 4.501997603, 46.51758593, 5.357414069, 39.1758, 47.78462922, 58.47761343, 16.22115577, 19.85792841, 6.006797281, 33.56955827, 8.988307016, 7.433779465, 5.732467532, 4.314985015, 49.70599401, 4.538784486, 43.13740763, 43.16759888, 4.16540076, 35.61031175, 42.33729762, 4.1967, 36.85282943, 53.46454963, 0, 4.00789842, 4.7295, 125.2315, 3.724055189, 30.6008588],
                        fill: true,
                        backgroundColor: 'rgb(51, 7, 39)',
                        tension: 0.1
                    },
    
                   
                    {
                        type: 'bar',
                        label: 'Pb',
                        data: [30, 18.25339728, 25.17722278, 5.69062375, 10.9148, 8.594249201, 6.945875774, 9.346084698, 7.021382894, 9.007494005, 9.7658, 54.36558065, 7.966620028, 0, 9.4960008, 9.796781287, 6.994303418, 0, 9.353475829, 0, 6.799000999, 4.609290709, 7.586665334, 8,.031256241, 9.338593688, 12.6665001, 8.419164668, 9.764841095, 12.7807, 8.977504499, 8.632314759, 0, 11.5864827, 12.1864, 0, 10.63847231, 13.21759537],
                        fill: true,
                        backgroundColor: 'rgb(116, 164, 204)',
                        tension: 0.1
                    }
    
                  ]
              },
              });
          }
  
  
      return (
          <div className='graf'>
            <NaviBar1 />
            {/* <SecondMenu /> */}
              <h2>Результати аналізу проб грунту у зоні впливу Роздільського ДГХП Сірка </h2>
              <h3>Вміст Mn, Cu, Pb</h3>
              <div className="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>1 м гудрони</td>
        <td>2</td> 
        <td>5 м гудрони</td> 
        <td>3</td>
        <td>10 м гудрони</td>
      </tr>
      <tr>
        <td>4</td>
        <td>20 м гудрони</td>
        <td>5</td>
        <td>1 м поверхня фосфогіпс</td>
        <td>6</td>
        <td>1 м 0.2 м фосфогіпс</td>
      </tr>
      <tr>
        <td>7</td>
        <td>1 м 0.4 м фосфогіпс</td>
        <td>8</td>
        <td>1 м 0.6 м фосфогіпс</td>
        <td>9</td>
        <td>5 м поверхня фосфогіпс</td>
      </tr>
      <tr>
        <td>10</td>
        <td>5 м 0.2 м фосфогіпс</td>
        <td>11</td>
        <td>5 м 0.4 м фосфогіпс</td>
        <td>12</td>
        <td>5 м 0.6 м фосфогіпс</td>
      </tr>
      <tr>
        <td>13</td>
        <td>10 м поверхня фосфогіпс</td>
        <td>14</td>
        <td>10 м 0.2 м фосфогіпс</td>
        <td>15</td>
        <td>10 м 0.4 м фосфогіпс</td>
      </tr>
      <tr>
        <td>16</td>
        <td>10 м 0.6 м фосфогіпс</td>
        <td>17</td>
        <td>20 м поверхня фосфогіпс</td>
        <td>18</td>
        <td>20 м 0.2 м фосфогіпс</td>
      </tr>
      <tr>
        <td>19</td>
        <td>20 м 0.4 м фосфогіпс</td>
        <td>20</td>
        <td>20 м 0.6 м фосфогіпс</td>
        <td>21</td>
        <td>1 м поверхня сірка</td>
      </tr>
      <tr>
        <td>22</td>
        <td>1 м 0.2 м сірка</td>
        <td>23</td>
        <td>1 м 0.4 м сірка</td>
        <td>24</td>
        <td>1 м 0.6 м сірка</td>
      </tr>
      <tr>
        <td>25</td>
        <td>5 м поверхня сірка</td>
        <td>26</td>
        <td>5 м 0.2 м сірка</td>
        <td>27</td>
        <td>5 м 0.4 м сірка</td>
      </tr>
      <tr>
        <td>28</td>
        <td>5 м 0.6 м сірка</td>
        <td>29</td>
        <td>10 м поверхня сірка</td>
        <td>30</td>
        <td>10 м 0.2 м сірка</td>
      </tr>
      <tr>
        <td>31</td>
        <td>10 м 0.4 м сірка</td>
        <td>32</td>
        <td>10 м 0.6 м сірка</td>
        <td>33</td>
        <td>20 м поверхня сірка</td>
      </tr>
      <tr>
        <td>34</td>
        <td>20 м 0.2 м сірка</td>
        <td>35</td>
        <td>20 м 0.4 м сірка</td>
        <td>36</td>
        <td>20 м 0.6 м сірка</td>
      </tr>
    </tbody>
  </table>
</div>
              <canvas id="BarChart" width="1000" height="400"/>
              
          </div>
      )
  }
  

export default Charts6;