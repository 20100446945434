import NaviBar1 from '../NaviBar1';

function Grynt  () {

  return (
  <>
  <NaviBar1 />
  <h1 className="karta">Аналіз проб грунту</h1> 
  <h6 className="description">Проби, які відібрано в 2016 р. на території Роздільського ДГХП "Сірка", 
проаналізовано мас-спектральним методом. Проби ґрунтів, які відібрано в 2017 р., проаналізовано за допомогою 
методу рентгенівської дифракції (XRD). Результати вимірювань отримано за різними 
показниками, зокрема, проаналізовано такі елементи, як: Стронцій, Манган, 
Цинк, Плюмбум, Арсен.</h6>

  </> 
  );
}   

  export default Grynt;