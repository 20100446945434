import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
// import SecondMenu from './SecondMenu';



Chart.register(zoomPlugin);

let LineChart;
// let MultiChart;

function Charts1() {
      useEffect(() => {
          buildLineChart();
        //   buildMultiChart();
      }, []);

      const buildLineChart = () => {
          var ctx = document.getElementById("LineChart").getContext("2d");

          if (typeof LineChart !== "undefined") LineChart.destroy();

          Chart.register(annotationPlugin);

          const annotation = {
            type: 'line',
            borderColor: 'black',
            borderWidth: 1,
            display: (ctx) => ctx.chart.isDatasetVisible(1),
            label: {
              enabled: true,
              content: 'Modelling',
              position: 'start'
            },
            scaleID: 'x',
            value: '2020'
          };

          LineChart = new Chart(ctx, {
              type: 'line',
              data: {
                  labels: ['2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
                  datasets: [
                  {
                      label: 'Temperature',
                      data: [13.27, 13.46, 14.18, 12.92, 14.08, 13.75, 14.25, 12.17, 12.58, 12.69, 11.83, 11.50, 12.56, 12.16160679, 12.44708715, 12.82646982, 13.23162699, 13.58980238, 13.83667646, 13.92791673, 13.84713865, 13.608848, 13.25583592],
                      fill: false,
                      borderColor: 'rgb(75, 192, 192)',
                      tension: 0.1
                  },
                  {
                      label: 'pH',
                      data: [8.28, 8.39, 8.38, 8.41, 8.35, 8.42, 8.34, 8.39, 8.31, 8.29, 8.43, 8.41, 8.46, 8.420712162, 8.40484964, 8.36999067, 8.335149678, 8.319287728, 8.331055255, 8.364040978, 8.400273344, 8.420011939, 8.412502617],
                      fill: false,
                      borderColor: 'rgb(255,84,84)',
                      tension: 0.1
                  },

                  {
                      label: 'Oxygen',
                      data: [11.49, 11.55, 12.48, 14.63, 11.08, 13.04, 13.15, 12.10, 12.71, 12.33, 12.65, 11.67, 12.31, 12.46567851, 13.29436184, 12.1579067, 11.86774184, 13.14169395, 12.82818629, 11.70279232, 12.54957703, 13.2737152, 12.08379176],
                      fill: false,
                      borderColor: 'rgb(135, 0, 103)',
                      tension: 0.1
                  },

                  {
                      label: 'BOD 5',
                      data: [2.93, 2.09, 2.46, 3.11, 2.39, 3.11, 2.04, 2.64, 3.50, 3.04, 3.14, 2.80, 4.58, 2.49625042, 3.149141142, 2.39919223, 2.882208281, 2.900559087, 2.38974807, 3.145094892, 2.511823215, 2.715658513, 3.040191965],
                      fill: false,
                      borderColor: 'rgb(107, 231, 35)',
                      tension: 0.1
                  },

                  {
                      label: 'Mineralization',
                      data: [14.13, 14.42, 13.50, 11.95, 11.82, 11.03, 10.69, 12.08, 11.13, 6.18, 11.03, 10.35, 14.12, 13.99806498, 13.43960102, 12.66283061, 11.78978446, 10.95761819, 14.07947067, 14.07947067, 14.07947067, 14.07947067, 14.07947067],
                      fill: false,
                      borderColor: 'rgb(240, 103, 240)',
                      tension: 0.1
                  }
                  ]
              },
              options: {
                plugins: {
                  annotation: {
                    annotations: {
                      annotation
                    }
                  }
                }
              },
          })
       }

      return (
          <div>
            {/* <SecondMenu /> */}
              <h2>Прогноз стану водного середовища на 2021-2030 роки</h2>
              <h3>Результати оцінки та прогнозування</h3>
              <canvas id="LineChart" width="1000" height="400"/> 
              {/* <h3>Multi Chart</h3>
              <canvas id="MultiChart" width="1000" height="400"/>  */}
          </div>
      )
  }



export default Charts1;