import AtmoNavbar from '../lvivregion/AtmoNavbar';

function Atmo  () {

  return (
  <>
  <AtmoNavbar />
  <h1 className="karta">Аналіз проб повітря</h1> 
  <h6 className="description">Оцінка стану атмосферного повітря у місті Львові у IV кварталі 2022 року
здійснювалась шляхом порівняння середніх концентрацій забруднюючих
речовин з відповідними середньодобовими граничнодопустимими
концентраціями та порівняння максимально разових концентрацій
пріоритетних забруднюючих речовин з їх відповідними максимально разовими
гранично допустимими концентраціями.<br></br> 
Пріоритетними
забруднюючими речовинами вважаються ті речовини, які вносять найбільший
внесок у забруднення атмосферного повітря міста і контролюються на
більшості стаціонарних постів спостережень за забрудненням атмосферного
повітря.</h6>
  </> 
  );
}   

  export default Atmo;