import React from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import foto1 from './images/foto1.png';
import foto2 from './images/foto2.png';
import foto3 from './images/foto3.png';
import foto4 from './images/foto4.png';
import foto5 from './images/foto5.png';
import NavMenu from './NavMenu';

function ImageSlider () {
    return (
      <>
      <NavMenu />
      <h3 className="about__project">Галерея</h3>
    <MDBRow>
      <MDBCol lg={4} md={12} className='mb-4 mb-lg-0'>
        {/* <img
          src='https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp'
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        /> */}

        <img
          src={foto1}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Yosemite National Park'
        />

        {/* <img
          src='https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain1.webp'
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Wintry Mountain Landscape'
        /> */}

        <img
          src={foto2}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Yosemite National Park'
        />
      </MDBCol>

      <MDBCol lg={4} className='mb-4 mb-lg-0'>
        {/* <img
          src='https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain2.webp'
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Mountains in the Clouds'
        /> */}

        <img
          src={foto3}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Yosemite National Park'
        />

        {/* <img
          src='https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp'
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Boat on Calm Water'
        /> */}

        <img
          src={foto4}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Yosemite National Park'
        />

      </MDBCol>

      <MDBCol lg={4} className='mb-4 mb-lg-0'>
        {/* <img
          src='https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(18).webp'
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Waves at Sea'
        />

        <img
          src='https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain3.webp'
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Yosemite National Park'
        /> */}

        <img
          src={foto5}
          className='w-100 shadow-1-strong rounded mb-4'
          alt='Yosemite National Park'
        />
      </MDBCol>
    </MDBRow>
 



      
      </>
  );
}

  export default ImageSlider;