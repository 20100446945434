import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import NaviBar1 from './NaviBar1';
// import SecondMenu from './SecondMenu';

 
  Chart.register(zoomPlugin);
  
  let BarChart;
  
  
  function Charts2() {
      useEffect(() => {
          buildBarChart();
      }, []);
  
      const buildBarChart = () => {
          var ctx = document.getElementById("BarChart").getContext("2d");
  
          if (typeof BarChart !== "undefined") BarChart.destroy();
  
          BarChart = new Chart(ctx, {
              type: 'bar',
              data: {
                  labels: ['ГДК', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'],
                  datasets: [
                   {
                       type: 'bar',
                       label: 'Cr',
                       data: [100, 0, 13.13906094, 17.55007997, 0, 0, 0, 0, 0, 0, 0, 0, 0, 23.01009798, 13.39422116, 0, 0, 11.37647412, 10.5387535, 19.38541459, 5.793306693, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                       fill: true,
                       backgroundColor: 'rgb(161, 16, 251)',
                       tension: 0.1
                   },
                //    {
                //        type: 'bar',
                //        label: 'Mn',
                //        data: [1500, 159.3672062, 426.9061938, 308.6479408, 211.3603, 308.1783147, 181.4605552, 244.7110467, 199.4806155, 268.9061751, 149.7202, 204.2135719, 252.888267, 397.0191962, 229.5956809, 259.1394442, 243.2692385, 262.4675195, 280.3860368, 206.3150849, 156.3322677],
                //        fill: true,
                //        backgroundColor: 'rgb(251, 16, 231)',
                //        tension: 0.1
                //    },
                   {
                       type: 'bar',
                       label: 'Ni',
                       data: [85, 0, 13.95294705, 7.270491803, 0, 0, 0, 0, 0, 0, 0, 0, 0, 16.14747051, 0, 0, 0, 9.545972417, 11.553336, 9.825274725, 6.064335664, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                       fill: true,
                       backgroundColor: 'rgb(16, 251, 208)',
                       tension: 0.1
                  },
                //    {
                //        type: 'bar',
                //        label: 'Cu',
                //        data: [100, 6.507593925, 11.56453546, 4.62215114, 9.5916, 5.283146965, 34.205712, 4.501997603, 46.51758593, 5.357414069, 39.1758, 47.78462922, 58.47761343, 16.22115577, 19.85792841, 6.006797281, 33.56955827, 8.988307016, 7.433779465, 5.732467532, 4.314985015],
                //        fill: true,
                //        backgroundColor: 'rgb(51, 7, 39)',
                //        tension: 0.1
                //    },
                    {
                       type: 'bar',
                       label: 'Zn',
                       data: [300, 14.1006195, 4.817682318, 14.84256297, 14.1755, 8.955471246, 7.913521071, 6.070914902, 11.79786171, 8.206235012, 5.9964, 8.077053768, 8.802518489, 19.24555089, 15.92911418, 9.352958816, 9.029682191, 18.20717569, 3.085896924, 10.38501499, 12.57702298, 5.893206793, 2.365953619, 8.177950869, 7.238613664, 4.210773536, 8.016986411, 7.88956626, 5.3231, 8.634173165, 7.301977232, 0, 2.665066987, 13.018, 1.6139, 4.948410318, 17.38266427],
                       fill: true,
                       backgroundColor: 'rgb(12, 133, 125)',
                       tension: 0.1
                   },
                //    {
                //        type: 'bar',
                //        label: 'Sr',
                //        data: [1000, 4338.980815, 0, 61.48770492, 2964.9468, 3572.39976, 3357.205912, 4789.826508, 5123.332934, 3254.385891, 2701.9895, 3528.739456, 4263.553568, 0, 943.9006199, 2779.344162, 3375.041975, 613.0551669, 0, 0, 362.1181818],
                //        fill: true,
                //        backgroundColor: 'red',
                //        tension: 0.1
                //    },
                   {
                       type: 'bar',
                       label: 'As',
                       data: [2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4.810137972, 0, 0, 0, 5.672296622, 0, 2.437662338, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12.3342, 0, 0, ],
                       fill: true,
                       backgroundColor: 'red',
                       tension: 0.1
                   },
                //    {
                //        type: 'bar',
                //        label: 'Pb',
                //        data: [30, 18.25339728, 25.17722278, 5.69062375, 10.9148, 8.594249201, 6.945875774, 9.346084698, 7.021382894, 9.007494005, 9.7658, 54.36558065, 7.966620028, 0, 9.4960008, 9.796781287, 0, 0,  9.353475829, 0, 6.799000999],
                //        fill: true,
                //        backgroundColor: 'rgb(116, 164, 204)',
                //        tension: 0.1
                //    }
    
                  ]
              },
              });
          }
  
  
      return (
          <div className='graf'>
            <NaviBar1 />
            {/* <SecondMenu /> */}
              <h2>Результати аналізу проб грунту у зоні впливу Роздільського ДГХП Сірка </h2>
              <h3>Вміст Cr, Ni, Zn, As</h3>
              <div className="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>1 м гудрони</td>
        <td>2</td> 
        <td>5 м гудрони</td> 
        <td>3</td>
        <td>10 м гудрони</td>
      </tr>
      <tr>
        <td>4</td>
        <td>20 м гудрони</td>
        <td>5</td>
        <td>1 м поверхня фосфогіпс</td>
        <td>6</td>
        <td>1 м 0.2 м фосфогіпс</td>
      </tr>
      <tr>
        <td>7</td>
        <td>1 м 0.4 м фосфогіпс</td>
        <td>8</td>
        <td>1 м 0.6 м фосфогіпс</td>
        <td>9</td>
        <td>5 м поверхня фосфогіпс</td>
      </tr>
      <tr>
        <td>10</td>
        <td>5 м 0.2 м фосфогіпс</td>
        <td>11</td>
        <td>5 м 0.4 м фосфогіпс</td>
        <td>12</td>
        <td>5 м 0.6 м фосфогіпс</td>
      </tr>
      <tr>
        <td>13</td>
        <td>10 м поверхня фосфогіпс</td>
        <td>14</td>
        <td>10 м 0.2 м фосфогіпс</td>
        <td>15</td>
        <td>10 м 0.4 м фосфогіпс</td>
      </tr>
      <tr>
        <td>16</td>
        <td>10 м 0.6 м фосфогіпс</td>
        <td>17</td>
        <td>20 м поверхня фосфогіпс</td>
        <td>18</td>
        <td>20 м 0.2 м фосфогіпс</td>
      </tr>
      <tr>
        <td>19</td>
        <td>20 м 0.4 м фосфогіпс</td>
        <td>20</td>
        <td>20 м 0.6 м фосфогіпс</td>
        <td>21</td>
        <td>1 м поверхня сірка</td>
      </tr>
      <tr>
        <td>22</td>
        <td>1 м 0.2 м сірка</td>
        <td>23</td>
        <td>1 м 0.4 м сірка</td>
        <td>24</td>
        <td>1 м 0.6 м сірка</td>
      </tr>
      <tr>
        <td>25</td>
        <td>5 м поверхня сірка</td>
        <td>26</td>
        <td>5 м 0.2 м сірка</td>
        <td>27</td>
        <td>5 м 0.4 м сірка</td>
      </tr>
      <tr>
        <td>28</td>
        <td>5 м 0.6 м сірка</td>
        <td>29</td>
        <td>10 м поверхня сірка</td>
        <td>30</td>
        <td>10 м 0.2 м сірка</td>
      </tr>
      <tr>
        <td>31</td>
        <td>10 м 0.4 м сірка</td>
        <td>32</td>
        <td>10 м 0.6 м сірка</td>
        <td>33</td>
        <td>20 м поверхня сірка</td>
      </tr>
      <tr>
        <td>34</td>
        <td>20 м 0.2 м сірка</td>
        <td>35</td>
        <td>20 м 0.4 м сірка</td>
        <td>36</td>
        <td>20 м 0.6 м сірка</td>
      </tr>
    </tbody>
  </table>
</div>
              <canvas id="BarChart" width="1000" height="400"/>
          </div>
      )
  }
  

export default Charts2;