import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import NaviBar from './NaviBar';
// import SecondMenu from './SecondMenu';


Chart.register(zoomPlugin);

let BarChart;


function Charts5() {
    useEffect(() => {
        buildBarChart();
    }, []);

    const buildBarChart = () => {
        var ctx = document.getElementById("BarChart").getContext("2d");

        if (typeof BarChart !== "undefined") BarChart.destroy();

        BarChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['Kysle Lake', 'Chyste Lake', 'Serednie Lake', 'Hlyboke Lake'],
                datasets: [
                 {
                     type: 'bar',
                     label: 'SO4 2-',
                     data: [1114.3, 987.5, 885.9, 1022.1],
                     fill: true,
                     backgroundColor: 'rgb(16, 251, 149)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'P2O5',
                     data: [721.4, 5.7, 5.7, 5.4],
                     fill: true,
                     backgroundColor: 'rgb(114, 16, 251)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'PO4',
                     data: [482.61, 3.8, 3.8, 3.6],
                     fill: true,
                     backgroundColor: 'rgb(251, 16, 231)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'F',
                     data: [2970.5, 1609.4, 2136.7, 1926.2],
                     fill: true,
                     backgroundColor: 'rgb(107, 125, 96)',
                     tension: 0.1
                 },
                 {
                     type: 'bar',
                     label: 'Min',
                     data: [2720, 1689, 2990, 2370],
                     fill: true,
                     backgroundColor: 'rgb(42, 87, 15)',
                     tension: 0.1
                 },
                ]
            },
            });
        }


    return (
        <div className='graf'>
            <NaviBar />
            {/* <SecondMenu /> */}
            <h2>Результати аналізу проб води у зоні впливу Роздільського ДГХП Сірка </h2>
            <h3>Аналіз проб води в листопаді 2021 року</h3>
            <canvas id="BarChart" width="1000" height="400"/>
        </div>
    )
}



export default Charts5;