import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import Navigation from './Navigation';



Chart.register(zoomPlugin);

let BarChart;


function Charts5() {
    useEffect(() => {
        buildBarChart();
    }, []);

    const buildBarChart = () => {
        var ctx = document.getElementById("BarChart").getContext("2d");

        if (typeof BarChart !== "undefined") BarChart.destroy();

        BarChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['ГДК', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18'],
                datasets: [
                 {
                     type: 'bar',
                     label: 'Лужність',
                     data: [0, 610.0, 610.0, 366.0, 610.0, 366.0, 549.0, 427.0, 366.0, 402.6, 366.0, 610.0, 518.5, 518.5, 335.5, 378.2, 323.3],
                     fill: true,
                     backgroundColor: 'green',
                     tension: 0.1
                 },
                 {
                    type: 'bar',
                    label: 'Хлориди',
                    data: [300.0, 106.35, 120.53, 77.44, 124.07, 99.26, 120.53, 106.35, 60.26, 106.35, 70.9, 120.53, 124.07, 85.08, 124.07, 85.08, 88.62, 77.99, 81.53],
                    fill: true,
                    backgroundColor: 'rgb(12, 235, 79)',
                    tension: 0.1
                },
                {
                    type: 'bar',
                    label: 'Сухий залишок',
                    data: [1000.0, 734.0, 894.0, 517.0, 809.0, 813.0, 602.0, 697.0, 602.0, 575.0, 609.0, 782.0, 804.0, 788.0, 512.0, 481.0, 534.0, 609.0, 493.0],
                    fill: true,
                    backgroundColor: 'rgb(138, 235, 12)',
                    tension: 0.1
                },
                 
                ]
            },
            });
        }


    return (
        <div className='graf'>
            <Navigation />
            <h2>Показники якості поверхневих водних об’єктів на території Львівської територіальної громади за 4 квартал 2022 року</h2>
            <h4>Лужність. Хлориди. Сухий залишок</h4>
            <div className="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>потік поблизу Грибовицького полігону ТПВ</td>
        <td>2</td> 
        <td>річка «Полтва» (об’їзна дорога)</td> 
        <td>3</td>
        <td>річка «Млинівка» (с. Гряда)</td>
      </tr>
      <tr>
        <td>4</td>
        <td>річка «Миклашівка»</td>
        <td>5</td>
        <td>потік «Білогорський»</td>
        <td>6</td>
        <td>потік «Водяний»</td>
      </tr>
      <tr>
        <td>7</td>
        <td>потік «Лисиницький» (вул. Тракт-Глинянський, 150);</td>
        <td>8</td>
        <td>водойма на вул. Панаса Сотника;</td>
        <td>9</td>
        <td>річка «Зубра» на вул. Хоткевича,</td>
      </tr>
      <tr>
        <td>10</td>
        <td>потік у с. Підрясне</td>
        <td>11</td>
        <td>річка «Зимна Вода» (Вишенька)</td>
        <td>12</td>
        <td>потік смт Рудно (Сигнівка)</td>
      </tr>
      <tr>
        <td>13</td>
        <td>річка «Марунька»</td>
        <td>14</td>
        <td>водойма у парку «Піскові озера» (вул. Чупринки, 136)</td>
        <td>15</td>
        <td>водойма у парку «Архангела Михаїла»</td>
      </tr>
      <tr>
        <td>16</td>
        <td>річка «Зубра»</td>
        <td>17</td>
        <td>потік «Кривчицький» на вул. Старознесенській, 200</td>
        <td>18</td>
        <td>водойма у парку «Снопківський», вул. Бучми</td>
      </tr>
    </tbody>
  </table>
</div>
            <canvas id="BarChart" width="1000" height="400"/>
        </div>
    )
}



export default Charts5;