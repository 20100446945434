// import logo from './logo.svg';
import './App.css';
import React from "react";

// import NavState from './navState';
// import MainMenu from './MainMenu';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Main from './Main';
import { BrowserRouter as Router } from 'react-router-dom';
import Charts1 from './Charts1';
import Charts2 from './Charts2';
import Charts3 from './Charts3';
import Charts4 from './Charts4';
import Charts5 from './Charts5';
import Charts6 from './Charts6';
import Charts7 from './Charts7';
import Charts13 from './lvivregion/Charts13';
import Charts14 from './lvivregion/Charts14';
import Charts15 from './lvivregion/Charts15';
import Grynt from './pages/Grynt';
import Atmo from './pages/Atmo';
import MainSirka from './pages/MainSirka';
import SecondMenu from './SecondMenu';
import Water from './pages/Water';
import GroundWater from './pages/GroundWater';
import Vidxody from './pages/Vidxody';
import PovWater from './pages/PovWater';
import GryntMap from './GryntMap';
import WaterMap from './WaterMap';
import Header from './Header';
import ImageSlider from './ImageSlider';
import AtmChart from './lvivregion/AtmChart';

import WeatherApi from './pages/WeatherApi';


function App() {
  
  
  return (

<div className="App">
     <Header />
<div class="container-fluid">
  <div class="row">
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div class="position-sticky pt-3 sidebar-sticky"> 
        
        <SecondMenu />
      </div> 
    </nav>

    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/main" element={<Main/>}/>
        <Route exact path="/charts1" element={<Charts1/>}/>
        <Route exact path="/charts2" element={<Charts2/>}/>
        <Route exact path="/charts3" element={<Charts3/>}/>
        <Route exact path="/charts13" element={<Charts13/>}/>
        <Route exact path="/charts14" element={<Charts14/>}/>
        <Route exact path="/charts15" element={<Charts15/>}/>
        <Route exact path="/charts4" element={<Charts4/>}/>
        <Route exact path="/charts5" element={<Charts5/>}/>
        <Route exact path="/charts6" element={<Charts6/>}/>
        <Route exact path="/charts7" element={<Charts7/>}/>
        <Route exact path="/grynt" element={<Grynt/>}/> 
        <Route exact path="/water" element={<Water/>}/> 
        <Route exact path="/atmo" element={<Atmo/>}/> 
        <Route exact path="/mainsirka" element={<MainSirka/>}/> 
        <Route exact path="/povwater" element={<PovWater/>}/> 
        <Route exact path="/vidxody" element={<Vidxody/>}/> 
        <Route exact path="/gryntmap" element={<GryntMap/>}/> 
        <Route exact path="/watermap" element={<WaterMap/>}/> 
        <Route exact path="/imageslider" element={<ImageSlider/>}/>
        <Route exact path="/atmchart" element={<AtmChart/>}/>
        <Route exact path="/weatherapi" element={<WeatherApi/>}/>
      </Routes>
    </Router>
      
    </main> 
  </div>
</div> 
  </div>
  );
}

export default App;
