import NavMenu from "./NavMenu";

function Home  () {

   return (
    
      
    
       <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
       
         {/* <h1 className="h2">Головна </h1> */}
         <div className="btn-toolbar mb-2 mb-md-0">
          <NavMenu />
           <div className="btn-group me-2">
           </div>
            {/* <h3>Information and analytical system of ecological monitoring of the territory of SE "Rozdil Mining and Chemical Enterprise "Sirka"</h3> */}
            <h1 className="homepage">Інформаційно-аналітична система моніторингу Львівської області</h1>
            <h6 className="description">Створена за узагальненою інформацією щодо забруднення атмосферного повітря, стану поверхневих вод,
радіаційного стану та наявності відходів на території області.
Екологічний моніторинг стану та забруднювачів атмосферного повітря у
Львівській області здійснював Львівський регіональний центр з
гідрометеорології.<br></br>
Інформацію про результати досліджень показників стану забруднення
атмосферного повітря м. Львова надано КП «Адміністративно - технічне
управління» Львівської міської ради.<br></br>
Аналіз стану поверхневих вод виконано на основі даних спостережень за
вмістом гідрохімічних показників, наданих Волинським обласним центром з
гідрометеорології.
Результати спостережень щодо стану поверхневих вод у м. Львові надано
КП «Адміністративно - технічне управління» Львівської міської ради.
Інформацію про обсяги скидів в річці Полтва забруднюючих речовин після
каналізаційно-очисних споруд надав ЛМКП «Львівводоканал».</h6>  
          </div>
        </div>
        
       
       );
 }   
     
    
  

  export default Home;