import MapComponent from "./MapGeojsonMarkers";
import NaviBar1 from './NaviBar1';
// import SecondMenu from './SecondMenu';

function Home  () {

  return (
  <>
  <NaviBar1 />
  <MapComponent/>
 </>
  );
}   
     
    
  

  export default Home;