import './Main.css'
import NavMenu from './NavMenu';




function Main () {
    return (
      <>
      <NavMenu />
      <h1 className='homepage'>Про проект</h1>
      <h6 className="description">
      Ідея створення обласної системи моніторингу природного довкілля вперше була представлена у Концепції системи моніторингу Львівщини у 1999 році, розвинута стосовно моніторингу природного довкілля м. Львова у 2000 році в Комплексній місцевій екологічній програмі “Львів 2000. Порядок денний на XXI сторіччя”. 
      <br></br>
      Подальші спроби створення системи були епізодичними, стосувалися окремих природних ресурсів і не відповідали основним вимогам до моніторингових досліджень – комплексності та періодичності.<br></br>
Вимоги сьогоднішньої системи прийняття рішень, необхідність відповідати міжнародним критеріям у цьому питанні ставлять невідкладні завдання з розробки регіональних систем оцінювання стану довкілля.  За умов законодавчого і нормативного неврегулювання проблеми на державному рівні розробка системи дослідження, оцінки та аналізу стану природного довкілля області стала одним з пріоритетних завдань держуправління.<br></br>
<br></br>
Необхідність розробки системи моніторингу навколишнього природного середовища у Львівській області викликана потребою:

ефективно реагувати на негативні зміни, які спостерігаються в екологічному стані області;<br></br>
інтеграції суб’єктів моніторингу довкілля в межах області з метою оптимізації процесів збору, первинної обробки, зберігання та передачі екологічної інформації;<br></br>
узагальнення даних щодо екологічного стану окремих природних ресурсів, які накопичуються різними організаціями – суб’єктами моніторингу природного довкілля з метою аналізу інформації та для надання відповідних довідок місцевим органам державної влади для забезпечення інформаційно-аналітичної підтримки рішень, що приймаються;<br></br>
підвищення рівня оперативності надання екологічної інформації, в тому числі при виникненні надзвичайних ситуацій;<br></br>
удосконалення за рахунок більш якісного інформаційного забезпечення системи контролю у сфері охорони довкілля та природокористування;<br></br>
підвищення ефективності розробки та виконання різних екологічних програм, в тому числі тих, що виконуються за міжнародними проектами;<br></br>
забезпечення всебічного інформування населення про стан довкілля у Львівській області, підвищення у владних структур і населення рівня екологічної культури та екологічних знань.<br></br>
 <br></br>
Згідно з «Програмою… » система моніторингу довкілля Львівщини передбачається як інтегрована інформаційна система, що здійснює збирання, оброблення та збереження екологічної інформації для відомчої та комплексної оцінки і прогнозу стану природних середовищ, біоти та умов життєдіяльності, вироблення обґрунтованих рекомендацій для прийняття ефективних соціальних та екологічних рішень на рівні обласної, міських та районних влад, удосконалення відповідних нормативно-правових актів, виконання зобов’язань України з міжнародних природоохоронних угод.
<br></br>
Метою створення системи моніторингу довкілля Львівщини є забезпечення адміністративних органів і відповідних служб області даними про стан довкілля та науково-обґрунтованими рекомендаціями щодо прийняття управлінських рішень з оперативного контролю стану довкілля та для запобігання негативних екологічних ситуаціям.
      </h6>
      </>
  );
}

  export default Main;