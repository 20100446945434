import MapComponent from "./MapGeojsonMarkers";
import NaviBar from './NaviBar';

function GryntMap  () {

    return (
    <>
    <NaviBar />
    <MapComponent/>
   </>
    );
  }   
       
      
    
  
    export default GryntMap;