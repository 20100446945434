import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import NaviBar1 from './NaviBar1';
// import SecondMenu from './SecondMenu';

 
  Chart.register(zoomPlugin);
  
  let BarChart;
  
  
  function Charts7() {
      useEffect(() => {
          buildBarChart();
      }, []);
  
      const buildBarChart = () => {
          var ctx = document.getElementById("BarChart").getContext("2d");
  
          if (typeof BarChart !== "undefined") BarChart.destroy();
  
          BarChart = new Chart(ctx, {
              type: 'bar',
              data: {
                  labels: ['ГДК', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'],
                  datasets: [
                   
                
                    {
                        type: 'bar',
                        label: 'Sr',
                        data: [1000, 4338.980815, 0, 61.48770492, 2964.9468, 3572.39976, 3357.205912, 4789.826508, 5123.332934, 3254.385891, 2701.9895, 3528.739456, 4263.553568, 0, 943.9006199, 2779.344162, 3375.041975, 613.0551669, 0, 0, 362.1181818, 4454.255245, 5569.545882, 3923.761334, 3340.127447, 5112.736458, 2930.073541, 3492.754447, 5018.7175, 2900.413417, 3033.376473, 0, 5663.278144, 4105.4317, 5863.8661, 4121.646471, 3817.702816],
                        fill: true,
                        backgroundColor: 'red',
                        tension: 0.1
                    },
                   
    
                  ]
              },
              });
          }
  
  
      return (
          <div className='graf'>
            <NaviBar1 />
            {/* <SecondMenu /> */}
              <h2>Результати аналізу проб грунту у зоні впливу Роздільського ДГХП Сірка </h2>
              <h3>Вміст Sr</h3>
              <div className="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>1 м гудрони</td>
        <td>2</td> 
        <td>5 м гудрони</td> 
        <td>3</td>
        <td>10 м гудрони</td>
      </tr>
      <tr>
        <td>4</td>
        <td>20 м гудрони</td>
        <td>5</td>
        <td>1 м поверхня фосфогіпс</td>
        <td>6</td>
        <td>1 м 0.2 м фосфогіпс</td>
      </tr>
      <tr>
        <td>7</td>
        <td>1 м 0.4 м фосфогіпс</td>
        <td>8</td>
        <td>1 м 0.6 м фосфогіпс</td>
        <td>9</td>
        <td>5 м поверхня фосфогіпс</td>
      </tr>
      <tr>
        <td>10</td>
        <td>5 м 0.2 м фосфогіпс</td>
        <td>11</td>
        <td>5 м 0.4 м фосфогіпс</td>
        <td>12</td>
        <td>5 м 0.6 м фосфогіпс</td>
      </tr>
      <tr>
        <td>13</td>
        <td>10 м поверхня фосфогіпс</td>
        <td>14</td>
        <td>10 м 0.2 м фосфогіпс</td>
        <td>15</td>
        <td>10 м 0.4 м фосфогіпс</td>
      </tr>
      <tr>
        <td>16</td>
        <td>10 м 0.6 м фосфогіпс</td>
        <td>17</td>
        <td>20 м поверхня фосфогіпс</td>
        <td>18</td>
        <td>20 м 0.2 м фосфогіпс</td>
      </tr>
      <tr>
        <td>19</td>
        <td>20 м 0.4 м фосфогіпс</td>
        <td>20</td>
        <td>20 м 0.6 м фосфогіпс</td>
        <td>21</td>
        <td>1 м поверхня сірка</td>
      </tr>
      <tr>
        <td>22</td>
        <td>1 м 0.2 м сірка</td>
        <td>23</td>
        <td>1 м 0.4 м сірка</td>
        <td>24</td>
        <td>1 м 0.6 м сірка</td>
      </tr>
      <tr>
        <td>25</td>
        <td>5 м поверхня сірка</td>
        <td>26</td>
        <td>5 м 0.2 м сірка</td>
        <td>27</td>
        <td>5 м 0.4 м сірка</td>
      </tr>
      <tr>
        <td>28</td>
        <td>5 м 0.6 м сірка</td>
        <td>29</td>
        <td>10 м поверхня сірка</td>
        <td>30</td>
        <td>10 м 0.2 м сірка</td>
      </tr>
      <tr>
        <td>31</td>
        <td>10 м 0.4 м сірка</td>
        <td>32</td>
        <td>10 м 0.6 м сірка</td>
        <td>33</td>
        <td>20 м поверхня сірка</td>
      </tr>
      <tr>
        <td>34</td>
        <td>20 м 0.2 м сірка</td>
        <td>35</td>
        <td>20 м 0.4 м сірка</td>
        <td>36</td>
        <td>20 м 0.6 м сірка</td>
      </tr>
    </tbody>
  </table>
</div>
              <canvas id="BarChart" width="1000" height="400"/>
          </div>
      )
  }
  

export default Charts7;