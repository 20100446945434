import React, {useEffect} from 'react'
import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom';
import Navigation from './Navigation';


Chart.register(zoomPlugin);

let BarChart;


function Charts4() {
    useEffect(() => {
        buildBarChart();
    }, []);

    const buildBarChart = () => {
        var ctx = document.getElementById("BarChart").getContext("2d");

        if (typeof BarChart !== "undefined") BarChart.destroy();

        BarChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['ГДК', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18'],
                datasets: [
                 {
                     type: 'bar',
                     label: 'Нітрати',
                     data: [40.0, 22.5, 16.4, 8.3, 5.2, 19.2, 17.8, 7.6, 4.12, 14.8, 13.2, 18.3, 8.6, 21.0, 17.6, 12.7, 9.4, 7.4, 11.8],
                     fill: true,
                     backgroundColor: 'rgb(29, 240, 64)',
                     tension: 0.1
                 },
                 {
                    type: 'bar',
                    label: 'Сульфати',
                    data: [100.0, 81.0, 78.0, 61.0, 83.0, 79.0, 55.0, 81.0, 48.0, 51.0, 56.0, 84.0, 72.0, 79.0, 49.0, 47.0, 64.0, 49.0, 59.0],
                    fill: true,
                    backgroundColor: 'rgb(12, 235, 123)',
                    tension: 0.1
                },
                {
                    type: 'bar',
                    label: 'Завислі речовини',
                    data: [25.0, 182.0, 91.5, 62.5, 41.5, 98.5, 84.5, 57.5, 54.5, 57.5, 46.5, 103.0, 91.5, 54.5, 120.0, 103.0, 31.5, 53.5, 31.5],
                    fill: true,
                    backgroundColor: 'red',
                    tension: 0.1
                },
                {
                    type: 'bar',
                    label: 'ХСК',
                    data: [50.0, 80.0, 140.0, 60.0, 60.0, 80.0, 100.0, 80.0, 80.0, 60.0, 60.0, 80.0, 60.0, 80.0, 40.0, 20.0, 40.0, 40.0, 40.0],
                    fill: true,
                    backgroundColor: 'rgb(235, 90, 12)',
                    tension: 0.1
                },
                {
                    type: 'bar',
                    label: 'БСК-5',
                    data: [6.0, 32.8, 44.8, 22.4, 24.0, 28.8, 41.0, 30.4, 33.6, 26.4, 22.4, 28.0, 22.4, 31.7, 16.4, 7.0, 15.6, 17.6, 15.6],
                    fill: true,
                    backgroundColor: 'red',
                    tension: 0.1
                },
                 
                ]
            },
            });
        }


    return (
        <div className='graf'>
            <Navigation />
            <h2>Показники якості поверхневих водних об’єктів на території Львівської територіальної громади за 4 квартал 2022 року</h2>
            <h4>Нітрати. Сульфати. Завислі речовини. ХСК. БСК-5</h4>

            <div className="table-responsive">
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
        <th scope="col">#</th>
        <th scope="col">Точка відбору проб</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>потік поблизу Грибовицького полігону ТПВ</td>
        <td>2</td> 
        <td>річка «Полтва» (об’їзна дорога)</td> 
        <td>3</td>
        <td>річка «Млинівка» (с. Гряда)</td>
      </tr>
      <tr>
        <td>4</td>
        <td>річка «Миклашівка»</td>
        <td>5</td>
        <td>потік «Білогорський»</td>
        <td>6</td>
        <td>потік «Водяний»</td>
      </tr>
      <tr>
        <td>7</td>
        <td>потік «Лисиницький» (вул. Тракт-Глинянський, 150);</td>
        <td>8</td>
        <td>водойма на вул. Панаса Сотника;</td>
        <td>9</td>
        <td>річка «Зубра» на вул. Хоткевича,</td>
      </tr>
      <tr>
        <td>10</td>
        <td>потік у с. Підрясне</td>
        <td>11</td>
        <td>річка «Зимна Вода» (Вишенька)</td>
        <td>12</td>
        <td>потік смт Рудно (Сигнівка)</td>
      </tr>
      <tr>
        <td>13</td>
        <td>річка «Марунька»</td>
        <td>14</td>
        <td>водойма у парку «Піскові озера» (вул. Чупринки, 136)</td>
        <td>15</td>
        <td>водойма у парку «Архангела Михаїла»</td>
      </tr>
      <tr>
        <td>16</td>
        <td>річка «Зубра»</td>
        <td>17</td>
        <td>потік «Кривчицький» на вул. Старознесенській, 200</td>
        <td>18</td>
        <td>водойма у парку «Снопківський», вул. Бучми</td>
      </tr>

      
    </tbody>
  </table>
</div>

            <canvas id="BarChart" width="1000" height="400"/>
        </div>
    )
}



export default Charts4;